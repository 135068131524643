import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Typography, Box, Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Paper,
  CircularProgress,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from '@mui/material';
import { collection, query, orderBy, getDocs, where, limit } from 'firebase/firestore';
import { db } from '../firebase';

const LayupsPage = () => {
  const [courses, setCourses] = useState([]);
  const [departmentCourses, setDepartmentCourses] = useState([]);
  const [distribCourses, setDistribCourses] = useState([]); // State for distribs courses
  const [departments, setDepartments] = useState([]);
  const [distribs, setDistribs] = useState([]); // State for unique distribs
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedDistrib, setSelectedDistrib] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const [distribLoading, setDistribLoading] = useState(false); // Loading state for distrib courses
  const isMobile = useMediaQuery('(max-width:600px)');
  const initialPageSize = 30;

  // Function to fetch and cache courses data
  const fetchAndCacheCourses = useCallback(async () => {
    try {
      const cachedData = JSON.parse(localStorage.getItem('topCoursesCache'));
      const now = new Date().getTime();

      if (cachedData && now - cachedData.timestamp < 24 * 60 * 60 * 1000) { // 24 hours expiry
        setCourses(cachedData.courses);
        setLoading(false);
        return;
      }

      const q = query(
        collection(db, 'courses'),
        orderBy('layup', 'desc'),
        limit(initialPageSize)
      );

      const querySnapshot = await getDocs(q);
      const coursesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        department: doc.data().department,
        distribs: doc.data().distribs,
        numOfReviews: doc.data().numOfReviews,
        layup: doc.data().layup,
      }));

      console.log('Fetched courses data:', coursesData);

      const uniqueCoursesSet = new Set();
      const uniqueCourses = [];

      coursesData.forEach((course) => {
        const normalizedCourseName = course.name.trim().toLowerCase();
        const uniqueKey = `${normalizedCourseName}`;

        if (!uniqueCoursesSet.has(uniqueKey) && uniqueCourses.length < 15) {
          uniqueCoursesSet.add(uniqueKey);
          uniqueCourses.push(course);
        } else {
          console.log('Duplicate found or limit reached:', uniqueKey);
        }
      });

      console.log('Unique courses:', uniqueCourses);

      // Cache the data in local storage with a timestamp
      localStorage.setItem('topCoursesCache', JSON.stringify({
        courses: uniqueCourses,
        timestamp: now,
      }));

      setCourses(uniqueCourses);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setError('Failed to fetch courses.');
      setLoading(false);
    }
  }, [initialPageSize]);

  // Fetch department courses based on the selected department
  const fetchDepartmentCourses = useCallback(async (department) => {
    try {
      setDepartmentLoading(true);
      const q = query(
        collection(db, 'courses'),
        where('department', '==', department),
        orderBy('layup', 'desc'),
        limit(5)
      );

      const querySnapshot = await getDocs(q);
      const coursesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        department: doc.data().department,
        distribs: doc.data().distribs,
        numOfReviews: doc.data().numOfReviews,
        layup: doc.data().layup,
      }));

      console.log('Fetched department courses data:', coursesData);

      setDepartmentCourses(coursesData);
    } catch (error) {
      console.error('Error fetching department courses:', error);
      setError('Failed to fetch department courses.');
    } finally {
      setDepartmentLoading(false);
    }
  }, []);

  // Fetch distrib courses based on the selected distrib
  const fetchDistribCourses = useCallback(async (distrib) => {
    try {
      setDistribLoading(true);

      // Fetch all courses without limit
      const q = query(
        collection(db, 'courses'),
        orderBy('layup', 'desc') // Order by layup first to help with sorting
      );

      const querySnapshot = await getDocs(q);
      const allCourses = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        department: doc.data().department,
        distribs: doc.data().distribs,
        numOfReviews: doc.data().numOfReviews,
        layup: doc.data().layup,
      }));

      // Filter courses where the distribs string includes the selected distrib
      const filteredCourses = allCourses
        .filter(
          (course) =>
            typeof course.distribs === 'string' &&
            course.distribs.split(',').map((d) => d.trim()).includes(distrib)
        )
        .sort((a, b) => b.layup - a.layup) // Sort by layup in descending order
        .slice(0, 5); // Take top 5

      console.log('Fetched distrib courses data:', filteredCourses);
      setDistribCourses(filteredCourses);
    } catch (error) {
      console.error('Error fetching distrib courses:', error);
      setError('Failed to fetch distrib courses.');
    } finally {
      setDistribLoading(false);
    }
  }, []);

  // Fetch departments and distribs to populate dropdowns
  const fetchDepartmentsAndDistribs = useCallback(async () => {
    try {
      const q = query(collection(db, 'courses'));
      const querySnapshot = await getDocs(q);
      const departmentsData = querySnapshot.docs.map((doc) => doc.data().department);
      const uniqueDepartments = [...new Set(departmentsData)];
      setDepartments(uniqueDepartments);

      // Extract unique distribs, assuming distribs is a comma-separated string
      const distribsData = querySnapshot.docs.flatMap((doc) => {
        const distribField = doc.data().distribs;
        if (typeof distribField === 'string') {
          return distribField.split(',').map((distrib) => distrib.trim()); // Split string and trim spaces
        }
        return []; // Handle unexpected types safely
      });
      const uniqueDistribs = [...new Set(distribsData)];
      setDistribs(uniqueDistribs);
    } catch (error) {
      console.error('Error fetching departments and distribs:', error);
      setError('Failed to fetch departments and distribs.');
    }
  }, []);

  // Background fetch and cache courses on component mount
  useEffect(() => {
    fetchDepartmentsAndDistribs();
    fetchAndCacheCourses(); // Pre-fetch and cache top courses
  }, [fetchAndCacheCourses, fetchDepartmentsAndDistribs]);

  const handleDepartmentChange = (event) => {
    const department = event.target.value;
    setSelectedDepartment(department);
    if (department) {
      fetchDepartmentCourses(department);
    } else {
      setDepartmentCourses([]);
    }
  };

  const handleDistribChange = (event) => {
    const distrib = event.target.value;
    setSelectedDistrib(distrib);
    if (distrib) {
      fetchDistribCourses(distrib);
    } else {
      setDistribCourses([]);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#E4E2DD',
        padding: '40px', // Increased padding to match ProfilePage
      }}
    >
      {/* Live fetching indicator */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 35,
          display: 'flex',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            width: 10,
            height: 10,
            backgroundColor: '#F26655', // Purple color for the dot
            borderRadius: '50%',
            marginRight: '8px',
            animation: 'blinker 1.5s linear infinite',
            '@keyframes blinker': {
              '50%': { opacity: 0 },
            },
          }}
        />
        <Typography variant="body2" sx={{ fontWeight: 'semi-bold', fontFamily: 'SF Pro Display, sans-serif', color: 'black' }}>
          Fetching Live Courses
        </Typography>
      </Box>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          align="left"
          gutterBottom
          sx={{
            fontWeight: 600,
            marginBottom: '20px',
            fontFamily: 'SF Pro Display, sans-serif',
            color: '#571CE0', // Purple color for headings
          }}
        >
          The Biggest Layups Of All Time
        </Typography>

        {/* Note explaining what "layup" means */}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            marginBottom: '20px',
            fontFamily: 'SF Pro Display, sans-serif',
            color: '#1D1D1F',
          }}
        >
          <strong>Please Note:</strong> In the context of courses, "layup" refers to the perceived ease and workload of the course. A higher layup score typically indicates a course is easier and less time-consuming for students.
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <CircularProgress color="primary" />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ marginBottom: '20px' }}>
            {error}
          </Alert>
        ) : courses.length > 0 ? (
          <Card
            sx={{
              marginBottom: 4,
              padding: 4,
              backgroundColor: '#f9f9f9',
              color: '#1D1D1F',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: '12px',
              maxWidth: 1100,
              width: '100%', // Ensure the card takes up the full width of the container
            }}
          >
            <TableContainer component={Paper} sx={{ backgroundColor: '#fff', boxShadow: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', color: 'primary.main' }}>#</TableCell>
                    <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', color: 'primary.main' }}>Course Name</TableCell>
                    {!isMobile && (
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'primary.main' }}>
                        Distribs
                      </TableCell>
                    )}
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'primary.main' }}>
                      Num of Reviews
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'primary.main' }}>Layup</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {courses.map((course, index) => (
                    <TableRow
                      key={course.id}
                      component={Link}
                      to={`/departments/${course.department}/courses/${course.id}`}
                      sx={{
                        backgroundColor: index % 2 === 0 ? '#fafafa' : '#f4f4f4',
                        '&:hover': { backgroundColor: '#e0e0e0' },
                        cursor: 'pointer',
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                    >
                      <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'left' }}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'left' }}>
                        {course.name}
                      </TableCell>
                      {!isMobile && (
                        <TableCell sx={{ padding: '10px', textAlign: 'center' }}>
                          {typeof course.distribs === 'string'
                            ? course.distribs.split(',').map((distrib) => distrib.trim()).join(', ')
                            : 'N/A'}
                        </TableCell>
                      )}
                      <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'center' }}>
                        {course.numOfReviews}
                      </TableCell>
                      <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'center' }}>
                        {course.layup}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        ) : (
          <Typography>No courses available</Typography>
        )}
      </Container>

      <Container maxWidth="lg">
        <Card
          sx={{
            width: '100%',
            maxWidth: 1100,
            marginTop: '20px',
            boxShadow: 3,
            backgroundColor: '#f9f9f9',
            borderRadius: '12px',
            padding: 4,
            color: '#1D1D1F',
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              align="left"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: '20px',
                fontFamily: 'SF Pro Display, sans-serif',
                color: '#571CE0', // Purple color for headings
              }}
            >
            Layups by Department
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <FormControl sx={{ minWidth: 200, '& .MuiInputBase-input': { paddingTop: '10px', paddingBottom: '10px' } }}>
                <InputLabel
                  id="department-label"
                  sx={{ color: 'primary.main' }}
                  shrink={!!selectedDepartment}
                >
                  Department
                </InputLabel>
                <Select
                  labelId="department-label"
                  value={selectedDepartment}
                  label="Department"
                  onChange={handleDepartmentChange}
                  sx={{
                    height: '40px',
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary.main',
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        width: 250,
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Departments</em>
                  </MenuItem>
                  {departments.map((department, index) => (
                    <MenuItem key={index} value={department}>
                      {department}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {departmentLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <CircularProgress color="primary" />
              </Box>
            ) : error ? (
              <Alert severity="error" sx={{ marginBottom: '20px' }}>
                {error}
              </Alert>
            ) : departmentCourses.length > 0 ? (
              <TableContainer component={Paper} sx={{ backgroundColor: '#fff', boxShadow: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', color: 'primary.main' }}>#</TableCell>
                      <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', color: 'primary.main' }}>
                        Course Name
                      </TableCell>
                      {!isMobile && (
                        <TableCell sx={{ padding: '10px', textAlign: 'center' }}>Distribs</TableCell>
                      )}
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'primary.main' }}>
                        Num of Reviews
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'primary.main' }}>Layup</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {departmentCourses.map((course, index) => (
                      <TableRow
                        key={course.id}
                        component={Link}
                        to={`/departments/${course.department}/courses/${course.id}`}
                        sx={{
                          backgroundColor: index % 2 === 0 ? '#fafafa' : '#f4f4f4',
                          '&:hover': { backgroundColor: '#e0e0e0' },
                          cursor: 'pointer',
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                      >
                        <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'left' }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'left' }}>
                          {course.name}
                        </TableCell>
                        {!isMobile && (
                          <TableCell sx={{ padding: '10px', textAlign: 'center' }}>
                            {typeof course.distribs === 'string'
                              ? course.distribs.split(',').map((distrib) => distrib.trim()).join(', ')
                              : 'N/A'}
                          </TableCell>
                        )}
                        <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'center' }}>
                          {course.numOfReviews}
                        </TableCell>
                        <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'center' }}>
                          {course.layup}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>Select the department to see the biggest layups</Typography>
            )}
          </CardContent>
        </Card>
      </Container>

      <Container maxWidth="lg">
        <Card
          sx={{
            width: '100%',
            maxWidth: 1100,
            marginTop: '20px',
            boxShadow: 3,
            backgroundColor: '#f9f9f9',
            borderRadius: '12px',
            padding: 4,
            color: '#1D1D1F',
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              align="left"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: '20px',
                fontFamily: 'SF Pro Display, sans-serif',
                color: '#571CE0', // Purple color for headings
              }}
            >
              Layups by Distribs
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <FormControl sx={{ minWidth: 200, '& .MuiInputBase-input': { paddingTop: '10px', paddingBottom: '10px' } }}>
                <InputLabel id="distrib-label" sx={{ color: 'primary.main' }} shrink={!!selectedDistrib}>
                  Distrib
                </InputLabel>
                <Select
                  labelId="distrib-label"
                  value={selectedDistrib}
                  label="Distrib"
                  onChange={handleDistribChange}
                  sx={{
                    height: '40px',
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary.main',
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        width: 250,
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Distribs</em>
                  </MenuItem>
                  {distribs.map((distrib, index) => (
                    <MenuItem key={index} value={distrib}>
                      {distrib}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {distribLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <CircularProgress color="primary" />
              </Box>
            ) : error ? (
              <Alert severity="error" sx={{ marginBottom: '20px' }}>
                {error}
              </Alert>
            ) : distribCourses.length > 0 ? (
              <TableContainer component={Paper} sx={{ backgroundColor: '#fff', boxShadow: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', color: 'primary.main' }}>#</TableCell>
                      <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', color: 'primary.main' }}>
                        Course Name
                      </TableCell>
                      {!isMobile && (
                        <TableCell sx={{ padding: '10px', textAlign: 'center' }}>Distribs</TableCell>
                      )}
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'primary.main' }}>
                        Num of Reviews
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'primary.main' }}>Layup</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {distribCourses.map((course, index) => (
                      <TableRow
                        key={course.id}
                        component={Link}
                        to={`/departments/${course.department}/courses/${course.id}`}
                        sx={{
                          backgroundColor: index % 2 === 0 ? '#fafafa' : '#f4f4f4',
                          '&:hover': { backgroundColor: '#e0e0e0' },
                          cursor: 'pointer',
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                      >
                        <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'left' }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'left' }}>
                          {course.name}
                        </TableCell>
                        {!isMobile && (
                          <TableCell sx={{ padding: '10px', textAlign: 'center' }}>
                            {typeof course.distribs === 'string'
                              ? course.distribs.split(',').map((distrib) => distrib.trim()).join(', ')
                              : 'N/A'}
                          </TableCell>
                        )}
                        <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'center' }}>
                          {course.numOfReviews}
                        </TableCell>
                        <TableCell sx={{ padding: isMobile ? '5px' : '10px', textAlign: 'center' }}>
                          {course.layup}
                        </TableCell>
                      </TableRow>
                    ))}
                    
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>Select the distrib to see the biggest layups</Typography>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default LayupsPage;
