const departmentMapping = {
    AAAS: { name: "African and African American Studies", courses: 163 },
    AMEL: { name: "Asian and Middle Eastern Languages and Literatures", courses: 7 },
    AMES: { name: "Asian and Middle Eastern Studies", courses: 89 },
    ANTH: { name: "Anthropology", courses: 141 },
    ARAB: { name: "Arabic", courses: 47 },
    ARTH: { name: "Art History", courses: 185 },
    ASCL: { name: "Asian and Middle Eastern Studies", courses: 118 },
    ASTR: { name: "Astronomy", courses: 15 },
    BIOL: { name: "Biological Sciences", courses: 86 },
    CHEM: { name: "Chemistry", courses: 43 },
    CHIN: { name: "Chinese", courses: 41 },
    CLST: { name: "Classical Studies", courses: 71 },
    COSC: { name: "Computer Science", courses: 114 },
    COLT: { name: "Comparative Literature", courses: 166 },
    EARS: { name: "Earth Sciences", courses: 68 },
    ECON: { name: "Economics", courses: 55 },
    EDUC: { name: "Education", courses: 42 },
    ENGL: { name: "English", courses: 263 },
    ENGS: { name: "Engineering Sciences", courses: 88 },
    ENVS: { name: "Environmental Studies", courses: 65 },
    FILM: { name: "Film and Media Studies", courses: 131 },
    FREN: { name: "French", courses: 94 },
    FRIT: { name: "French and Italian in Translation", courses: 18 },
    GEOG: { name: "Geography", courses: 134 },
    GERM: { name: "German Studies", courses: 86 },
    GOVT: { name: "Government", courses: 277 },
    GRK: { name: "Greek", courses: 24 },
    HEBR: { name: "Hebrew", courses: 18 },
    HIST: { name: "History", courses: 242 },
    HUM: { name: "Humanities", courses: 11 },
    INTL: { name: "International Studies", courses: 18 },
    ITAL: { name: "Italian", courses: 54 },
    JAPN: { name: "Japanese", courses: 26 },
    JWST: { name: "Jewish Studies", courses: 91 },
    LACS: { name: "Latin American and Caribbean Studies", courses: 75 },
    LAT: { name: "Latin", courses: 37 },
    LATS: { name: "Latino Studies", courses: 26 },
    LING: { name: "Linguistics", courses: 55 },
    MATH: { name: "Mathematics", courses: 66 },
    MUS: { name: "Music", courses: 161 },
    NAS: { name: "Native American Studies", courses: 65 },
    PBPL: { name: "Public Policy", courses: 39 },
    PHIL: { name: "Philosophy", courses: 150 },
    PHYS: { name: "Physics", courses: 51 },
    PORT: { name: "Portuguese", courses: 31 },
    PSYC: { name: "Psychological and Brain Sciences", courses: 121 },
    QSS: { name: "Quantitative Social Science", courses: 37 },
    REL: { name: "Religion", courses: 201 },
    RUSS: { name: "Russian Language and Literature", courses: 62 },
    SART: { name: "Studio Art", courses: 45 },
    SOCY: { name: "Sociology", courses: 93 },
    SPAN: { name: "Spanish", courses: 138 },
    SPCM: { name: "Speech", courses: 16 },
    SSC: { name: "Social Science", courses: 1 },
    THEA: { name: "Theater", courses: 72 },
    TUC: { name: "Tuck Undergraduate Courses", courses: 3 },
    WGSS: { name: "Women's, Gender, and Sexuality Studies", courses: 152 },
    WRIT: { name: "Writing Courses", courses: 50 }
};

export default departmentMapping;
